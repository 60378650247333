import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  ToastContainer,
  Toast,
  InputGroup,
  Modal,
  Form
} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import './profilemodule.css';
import "./profileModule.css";
import CommonLayer from "../../layouts/master/index";
import { useDispatch, useSelector } from "react-redux";
import { userDetails, userUpdatePassword } from "../../../store/authSlice";
// import { CheckCircle } from "react-feather";
import { ErrorMessage, Field, Formik } from "formik";
import { passwordSchema } from "../../../schema/validationSchemas";
import { fetchCountry } from "../../../store/customerSlice";
import { useLoader } from "../../../context/LoaderContext";
import { Cancel01Icon, CheckmarkBadge04Icon } from "../../../icons/icons";
import CommomButton from "../../../components/common-button";
import CommonFormLabel from "../../../components/common-form-label";
import Input from "../../../components/input";
import { fetchStates } from "../../../store/eventsSlice";
// import SelectBox from "../../../components/select-box";

export default function Detail() {
  // const [value, setValue] = useState("");

  const { setLoading } = useLoader();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const [show, setShow] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const handleCloseChangePassword = () => setShowChangePassword(false);
  // const handleShowChangePassword = () => setShowChangePassword(true);

  //   const [name, setName] = useState("");
  //   const [email, setEmail] = useState("");
  //   const [mobileNumber, setMobileNumber] = useState("");
  const user = useSelector((state) => state.auth.user);
  // const countries = useSelector((state) => state.events.countries);

  // const states = useSelector((state) => state?.events?.states);

    // console.log('User : ', user);

    // console.log("states",states);
    

  const initialPasswordUpdate = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // setLoading(true);
    dispatch(userDetails()).then((action) => {
      if (action.payload && action.payload.data.success) {
        // console.log("Action : ", action.payload);
        // setName(action.payload.data.data.name);
        // setEmail(action.payload.data.data.email);
        // setMobileNumber(action.payload.data.data.mobile_number);
        // setCountry(action.payload.data.data.country_id);
      }
    });

    dispatch(fetchStates({}))

    dispatch(fetchCountry())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  // const getCountryName = (countryId) => {
  //   const country = countries.find((c) => c.id === countryId);
  //   return country ? country.name : "Not Found";
  // };

  const handlePasswordChange = (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    dispatch(userUpdatePassword(values))
      .then((action) => {
        if (action.payload && action.payload.data.success) {
          setSnackbarMessage(action.payload.data.message);
          setSnackbarVariant("toast-success");
          setShowSnackbar(true);
          resetForm();
        } else {
          setErrors(action.payload.data);
          setSnackbarMessage(
            action.payload.data.message || "Failed to update password"
          );
          setSnackbarVariant("toast-danger");
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage(
          "Update failed: Network or server error" + error.message
        );
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const titles = [
    { name: 'Profile', path: '/customers' },
  ];

  const getUserInitial = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n.charAt(0))
          .join("")
      : "UN";
  };


  return (
    <CommonLayer titles={titles}>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        {/* If you remove the d-none class from this given row tag, you will see the old design and code. */}
        <Row className="d-none">
          <Col lg={12} md={12} xs={12}>
            <div>
              <div className="d-flex justify-content-between align-items-center top_title">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0 subhead_title">Profile</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
              <Tabs
                defaultActiveKey="profilelist"
                className="mb-5 border-0 tablist_data"
                id="uncontrolled-tab-example"
              >
                <Tab
                  className="row customer_data"
                  eventKey="profilelist"
                  title="Profile"
                >
                  <div className="input_head position-relative d-flex align-items-center justify-content-between">
                    <h5 className="float-start m-0 fw-500">Profile Details</h5>
                  </div>
                  <div className="tablecontent_details col-8 position-relative border-radius-8">
                    <h6 className="fw-500">Personal Details</h6>
                    <div className="row justify-content-cener align-item-center pt-4 pb-5">
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          Name
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="text"
                          placeholder=""
                          disabled
                          value={user.name}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="w-100 text-start floating-label p-0">
                          Phone No.
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="number"
                          placeholder=" "
                          disabled
                          value={user.mobile_number}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          Email
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="email"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user.email}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          Organization Name
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="organization_name"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.organization_name}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        {/* <label class="floating-label w-100 text-start p-0">
                          Organization Type
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="organization_type"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.organization_type?.name}
                        /> */}
                        <div className="grid mb-4">
                        <div className="g-col-5">
                         
                        </div>
                      </div>
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          State
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="state"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.state?.name}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          City
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="city"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.city?.name}
                        />
                      </div>
                    </div>
                    {/* <div class="">
                      <Button type="button" class="">
                        Add Reseller
                      </Button>
                    </div> */}
                  </div>
                </Tab>
                <Tab
                  className="row customer_data"
                  eventKey="settingdetals"
                  title="Settings"
                >
                  <Formik
                    initialValues={initialPasswordUpdate}
                    validationSchema={passwordSchema}
                    onSubmit={handlePasswordChange}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="input_head position-relative d-flex align-items-center justify-content-between">
                          <h5 className="float-start m-0 fw-500">Setting</h5>
                          {showSnackbar}
                        </div>
                        <div className="tablecontent_details col-4 position-relative border-radius-8">
                          <h6 className="fw-500">Reset Password</h6>
                          <div className="row justify-content-cener align-item-center pt-4 pb-5 flex-column">
                            <div class="position-relative col-10 floating-label-content">
                              <InputGroup>
                                <label class="w-100 text-start p-0 floating-label">
                                  Current Password
                                </label>
                                <Field
                                  class="w-100 bg-white fs-16 floating-input"
                                  type="password"
                                  placeholder=""
                                  name="current_password"
                                //   autoComplete="off"
                                />

                                <div className="required_alert text-start">
                                  <ErrorMessage
                                    name="current_password"
                                    component="p"
                                    className="text-danger"
                                  />
                                </div>
                              </InputGroup>
                            </div>
                            <div class="position-relative col-10 floating-label-content">
                              <InputGroup>
                                <label class="w-100 text-start p-0 floating-label">
                                  New Password
                                </label>
                                <Field
                                  class="w-100 bg-white fs-16 floating-input"
                                  type="password"
                                  placeholder=""
                                  name="new_password"
                                />

                                <div className="required_alert text-start">
                                  <ErrorMessage
                                    name="new_password"
                                    component="p"
                                    className="text-danger"
                                  />
                                </div>
                              </InputGroup>
                            </div>
                            <div class="position-relative col-10 floating-label-content">
                              <InputGroup>
                                <label class="w-100 text-start p-0 floating-label">
                                  Confirm Password
                                </label>
                                <Field
                                  class="w-100 bg-white fs-16 floating-input"
                                  type="password"
                                  placeholder=" "
                                  name="new_password_confirmation"
                                />

                                <div className="required_alert text-start">
                                  <ErrorMessage
                                    name="new_password_confirmation"
                                    component="p"
                                    className="text-danger"
                                  />
                                </div>
                              </InputGroup>
                            </div>
                          </div>
                          <div class="">
                            <Button type="submit" class="btn btn-primary">
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>


        <div className="profile-content">
          <h4>My Profile</h4>
          <div className="mt-4 pt-1 grid">
            <div className="g-col-6">
              <div className="bg-white rounded-2 p-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2 profile-icon">
                    <div className="icon rounded-circle d-flex align-items-center justify-content-center text-white text-capitalize">{getUserInitial(user.name)}</div>
                    <h6>{user.name}</h6>
                  </div>
                  {/* <Button varient="link" className="bg-transparent border-0 edit-link" onClick={handleShow}>Edit</Button> */}
                </div>
                <h6 className="mt-4">Personal Details</h6>
                <div className="mt-4 grid gap-3">
                  <div className="g-col-6">
                    <h6>Email:</h6>
                    <p>{user.email}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>Phone:</h6>
                    <p>{user.mobile_number}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>Organization:</h6>
                    <p>{user?.organization_name}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>Organization Type:</h6>
                    <p>{user?.organization_type?.name}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>State:</h6>
                    <p>{user?.state?.name}</p>
                  </div>
                  <div className="g-col-6">
                    <h6>City:</h6>
                    <p>{user?.city?.name}</p>
                  </div>
                  {/* <div className="g-col-6">
                    <CommomButton
                      title="Change Password"
                      onClick={handleShowChangePassword}
                      className="btn-primary common-one-shadow"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Modal show={show} onHide={handleClose}
        size="lg"
        centered
        dialogClassName="common-popup-dialog common-popup-dialog-size"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop">

        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleClose}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="common-mb-space text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Edit Profile</h4>
            <p>Edit {user.name} Profile</p>
          </div>
          <Form className="grid">
            <div className="g-col-6">
              <CommonFormLabel htmlFor="name" title="Name" />
              <Input
                name="name"
                type="text"
                // value={values.name}
                placeholder="Enter Your Name"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="name" title="E-mail" />
              <Input
                name="email"
                type="email"
                // value={values.name}
                placeholder="Enter Your E-mail"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="name" title="Phone No." />
              <Input
                name="phone no."
                type="number"
                // value={values.name}
                placeholder="Enter Your Phone No."
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="name" title="Organization" />
              <Input
                name="organization"
                type="text"
                // value={values.name}
                placeholder="Enter Your Organization"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="name" title="Organization Type" />
              <Input
                name="organization type"
                type="text"
                // value={values.name}
                placeholder="Enter Your Organization Type"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="name" title="State" />
              <Input
                name="state"
                type="text"
                // value={values.name}
                placeholder="Enter Your State"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>
            <div className="g-col-6">
              <CommonFormLabel htmlFor="name" title="City" />
              <Input
                name="city"
                type="text"
                // value={values.name}
                placeholder="Enter Your City"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>

            <div className="g-col-12 mt-3">
              <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                <CommomButton
                  title="Update"
                  type="submit"
                  // disabled={isSubmitting}
                  className="btn-primary primary-shadow"
                />
                <CommomButton
                  title="Cancel"
                  onClick={handleClose}
                  className="btn-muted"
                />
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showChangePassword} onHide={handleCloseChangePassword}
        centered
        dialogClassName="common-popup-dialog common-popup-dialog-size"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop">

        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-colose-btn"
            onClick={handleCloseChangePassword}
          >
            <Cancel01Icon
              width={16}
              height={16}
              color="#BCBBC1"
              strokeWidth="2.5"
            />
          </div>
          <div className="common-mb-space text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Change Password</h4>
            <p>Change Password {user.name} Profile</p>
          </div>
          <Form className="grid gap-3">
            <div className="g-col-12">
              <CommonFormLabel htmlFor="name" title="New Password" />
              <Input
                name="new password"
                type="number"
                // value={values.name}
                placeholder="Enter Your New Password"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>
            <div className="g-col-12">
              <CommonFormLabel htmlFor="name" title="Confirm Password" />
              <Input
                name="confirm password"
                type="number"
                // value={values.name}
                placeholder="Enter Your Confirm Password"
                className='shadow-none'
              // className={`shadow-none ${errors.name && touched.name ? "error" : "correct"
              //   }`}
              />
              {/* {errors.name && touched.name ? (
                <ValidationFeedback title={errors.name} />
              ) : null} */}
            </div>

            <div className="g-col-12 mt-3">
              <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                <CommomButton
                  title="Update"
                  type="submit"
                  // disabled={isSubmitting}
                  className="btn-primary primary-shadow"
                />
                <CommomButton
                  title="Cancel"
                  onClick={handleCloseChangePassword}
                  className="btn-muted"
                />
              </div>
            </div>
          </Form>
        </Modal.Body>

      </Modal>
    </CommonLayer>
  );
}
