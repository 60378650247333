import React, { useCallback, useEffect } from "react";
import CommonLayout from "../../../container/layouts/master/index";
import CampaignsChart from "../../../components/charts/campaigns-chart";
import CampaignsReportChart from "./../../../components/charts/campaigns-report-chart";
import CreditHistoryChart from "./../../../components/charts/credit-history-chart"
import BaisicWidgets from "./../../../components/widget/baisic-widgets/index";
import { fetchDashboardData } from "../../../store/eventsSlice";
import { Col, Image, OverlayTrigger, Row, Form } from "react-bootstrap";
import { Tooltip as TT } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useLoader } from "../../../context/LoaderContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import moment from "moment";

import { AlertTriangle } from "react-feather";
import "./dashboard.css";
import { Calendar03Icon, Clock01Icon, UserGroup03Icon, Coins01Icon, WhatsappIcon, MessageBlockedIcon, CoinsSwapIcon, Coins02Icon, DatabaseIcon, WaterfallUp01Icon } from "../../../icons/icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();

  const dashboardData = useSelector((state) => state.events?.dashboardData);
  const expiry_date = useSelector((state) => state.auth?.user?.expiry_date);

  const daysUntilExpiry = moment(expiry_date).diff(moment(), "days");

  const doughnutData = {
    labels: ["Failed", "In Process", "Pending", "Completed"],
    datasets: [
      {
        data: [
          dashboardData?.total_failed_campaigns || 0,
          dashboardData?.total_in_process_campaigns || 0,
          dashboardData?.total_pending_campaigns || 0,
          dashboardData?.total_completed_campaigns || 0,
          dashboardData?.total_drafted_campaigns || 0,
          dashboardData?.total_scheduled_campaigns || 0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        cutout: "70%",
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const getDashboardData = useCallback(() => {
    // setLoading(true);

    dispatch(fetchDashboardData({}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  const titles = [{ name: "Dashboard", path: "/customers" }];

  return (
    <>
      <CommonLayout titles={titles}>
        <div className="p-4 d-none">
          <Row className="my-5 g-4 cerdit-history-content">
            <h3>My Credits</h3>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="mb-0 text-capitalize">Total Credits</p>
                    <span>{dashboardData?.total_credits || 0}</span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Total Used Credits</p>
                    <span>
                      {dashboardData?.total_credits -
                        dashboardData?.available_credits || 0}
                    </span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Total Reserved Credits</p>
                    <span>{dashboardData?.reserved_credits || 0}</span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <TT id="tooltip-top">
                          Credits are reserved for scheduled campaigns
                        </TT>
                      }
                    >
                      <Image
                        src="/images/modal-icon/info-icon.svg"
                        alt="info-icon"
                        className="me-1"
                        width={15}
                        height={15}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div
                className={`p-4 rounded-3 cedit-h-card h-100 ${dashboardData?.available_credits < 100 ? "border-warning" : ""
                  }`}
                style={{
                  border:
                    dashboardData?.available_credits < 100
                      ? "2px solid red"
                      : "2px solid transparent",
                }}
              >
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Available Credits</p>
                    <span>{dashboardData?.available_credits || 0}</span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3 position-relative">
                  <div className=" ">
                    <p className="text-capitalize">Expire On</p>
                    <span>{moment(expiry_date).format("DD MMMM, YYYY")}</span>
                  </div>
                  {daysUntilExpiry <= 10 && (
                    <div className="credit-h-icon d-flex align-items-center justify-content-center">
                      <AlertTriangle size={24} color="red"></AlertTriangle>
                    </div>
                  )}
                </div>
                {daysUntilExpiry <= 10 && (
                  <div className="scrolling-text-container pt-3">
                    <p className="scrolling-text">
                      Your account is expiring in {daysUntilExpiry} days!
                    </p>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row className="my-5 g-4 cerdit-history-content gap-4">
            <h3>Customers</h3>
            <Row>
              <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card h-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="mb-0 text-capitalize">Total</p>
                      <span>{dashboardData?.total_customers}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card h-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="text-capitalize">Connected</p>
                      <span>{dashboardData?.total_active_customers}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card h-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="text-capitalize">Not Connected</p>
                      <span>{dashboardData?.total_inactive_customers}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xxl={3} xl={6} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card h-100">
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="text-capitalize">Campaigns</p>
                        <span>{dashboardData?.total_campaigns}</span>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                    </div>
                  </div>
                  {dashboardData?.total_campaigns ? (
                    <>
                      <div
                        style={{ height: "200px" }}
                        className="position-relative"
                      >
                        <Doughnut
                          data={doughnutData}
                          options={doughnutOptions}
                        />
                        <div className=" position-absolute top-50 start-50 translate-middle text-center">
                          <span className="mb-0">
                            {dashboardData?.total_campaigns || 2758}
                          </span>
                          <p>Total</p>
                        </div>
                      </div>
                      <div className="mt-3">
                        <ul className="list-unstyled p-0">
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(255, 99, 132, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            <p className="mb-0">
                              {" "}
                              Failed:{" "}
                              {dashboardData?.total_failed_campaigns || 0}
                            </p>
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(255, 206, 86, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            <p className="mb-0">
                              Inprogress:{" "}
                              {dashboardData?.total_in_process_campaigns || 0}
                            </p>
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(75, 192, 192, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            <p className="mb-0">
                              {" "}
                              Pending:{" "}
                              {dashboardData?.total_pending_campaigns || 0}
                            </p>
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(54, 162, 235, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            <p className="mb-0">
                              Completed:{" "}
                              {dashboardData?.total_completed_campaigns || 0}
                            </p>
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(153, 102, 255, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            <p className="mb-0">
                              Drafted:{" "}
                              {dashboardData?.total_drafted_campaigns || 0}
                            </p>
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(255, 159, 64, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            <p className="mb-0">
                              {" "}
                              Scheduled:{" "}
                              {dashboardData?.total_scheduled_campaigns || 0}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Row>

          <Row className="my-5 g-4 cerdit-history-content">
            <Col xxl={3} xl={6} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="text-capitalize">Campaigns</p>
                      <span>{dashboardData?.total_campaigns}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
                {dashboardData?.total_campaigns ? (
                  <>
                    <div
                      style={{ height: "200px" }}
                      className="position-relative"
                    >
                      <Doughnut data={doughnutData} options={doughnutOptions} />
                      <div className=" position-absolute top-50 start-50 translate-middle text-center">
                        <span>{dashboardData?.total_campaigns || 2758}</span>
                        <p>Total</p>
                      </div>
                    </div>
                    <div className="mt-3">
                      <ul className=" list-unstyled p-0">
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(255, 99, 132, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          <p className="mb-0">
                            Failed: {dashboardData?.total_failed_campaigns || 0}
                          </p>
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(255, 206, 86, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          <p className="mb-0">
                            Inprogress:{" "}
                            {dashboardData?.total_in_process_campaigns || 0}
                          </p>
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(75, 192, 192, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          <p className="mb-0">
                            {" "}
                            Pending:{" "}
                            {dashboardData?.total_pending_campaigns || 0}
                          </p>
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(54, 162, 235, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          <p className="mb-0">
                            {" "}
                            Completed:{" "}
                            {dashboardData?.total_completed_campaigns || 0}
                          </p>
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(153, 102, 255, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          <p className="mb-0">
                            Drafted:{" "}
                            {dashboardData?.total_drafted_campaigns || 0}
                          </p>
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(255, 159, 64, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          <p className="mb-0">
                            Scheduled:{" "}
                            {dashboardData?.total_scheduled_campaigns || 0}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
        <div className="dashboard-content">
          <div className="dashboard-header rounded-2 common-shadow bg-white">
            <div className="d-flex align-items-center gap-4 justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div className="date-icon rounded-1 d-flex align-items-center justify-content-center">
                  <Calendar03Icon
                    height={24}
                    width={24}
                    strokeWidth="1.8"
                    color="#A5596A"
                  />
                </div>
                <div>
                  <p className="text-capitalize">Subscription Expired on</p>
                  <small className="text-capitalize mt-1 fw-medium">
                    {moment(expiry_date).format("DD MMMM, YYYY")}
                  </small>
                </div>
              </div>
              <div className="d-flex align-items-center date-text gap-2">
                <Clock01Icon
                  height={16}
                  width={16}
                  strokeWidth="1.8"
                  color="#6d6b77"
                />
                <span>12:45:00</span>
              </div>
            </div>
          </div>
          <div className="dashboard-body">
            <div>
              <h4 className="fw-medium">Overview</h4>
              <div className="grid gap-4 row-gap-xxl-0 dashboard-body-overview mt-4">
                <div className="g-col-6 g-col-xxl-2">
                  <div className="grid gap-4 column-gap-0">
                    <div className="g-col-12">
                      <div className="bg-white common-shadow rounded-2 py-1">
                        <BaisicWidgets title="Total Customer" number={dashboardData?.total_customers} icon={<UserGroup03Icon height={48} width={48} color="#F6F5ED" strokeWidth="1.5" />} />
                      </div>
                    </div>
                    <div className="g-col-12">
                      <div className="bg-white common-shadow rounded-2 py-1">
                        <BaisicWidgets title="Connected" number={dashboardData?.total_active_customers} icon={<WhatsappIcon height={48} width={48} color="#EEFEEC" strokeWidth="1.5" />} />
                      </div>
                    </div>
                    <div className="g-col-12">
                      <div className="bg-white common-shadow rounded-2 py-1">
                        <BaisicWidgets title="Not Connected" number={dashboardData?.total_inactive_customers} icon={<MessageBlockedIcon height={48} width={48} color="#FCF0F0" strokeWidth="1.5" />} />
                      </div>
                    </div>

                  </div>
                </div>
                <div className="g-col-6 g-col-xxl-3">
                  <div className="rounded-2 common-shadow bg-white px-3 py-4">
                    <div className="d-flex align-items-center justify-content-between gap-3">
                      <h5 className="text-capitalize">Campaigns</h5>
                      <div className="custom-year-select">
                        <Form.Select
                          aria-label="Default select example"
                          className="shadow-none"
                        >
                          <option>2020</option>
                          <option value="1">2021</option>
                          <option value="2">2022</option>
                          <option value="3">2023</option>
                          <option value="4">2024</option>
                          <option value="5">2025</option>
                        </Form.Select>
                      </div>
                    </div>
                    <CampaignsChart className="mt-3" />
                  </div>
                </div>
                <div className="g-col-12 g-col-xxl-7">
                  <div className="rounded-2 bg-white common-shadow grid gap-0">
                    <div className="px-3 py-4 g-col-8 border-end">
                      <h5 className="text-capitalize">Campaigns</h5>
                      <CampaignsReportChart className="mt-3" />
                    </div>
                    <div className="px-3 py-4 g-col-4">
                      <div className="d-flex align-items-center h-100 flex-column justify-content-between gap-3">
                        <div className="custom-year-select">
                          <Form.Select
                            aria-label="Default select example"
                            className="shadow-none"
                          >
                            <option>2020</option>
                            <option value="1">2021</option>
                            <option value="2">2022</option>
                            <option value="3">2023</option>
                            <option value="4">2024</option>
                            <option value="5">2025</option>
                          </Form.Select>
                        </div>

                        <div className="text-center total-campaign-text">
                          <h4 className="fw-medium">500</h4>
                          <p className="pt-1">Total Campaigns</p>
                        </div>

                        <div className="text-center d-flex align-items-center gap-2 cursor-pointer total-campaign-text">
                          <span className="rounded-circle"></span>
                          <p>Campaigns</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 ">
              <h4 className="fw-medium">My Credits</h4>
              <div className="grid gap-4  row-gap-xxl-0 mt-4">
                <div className="g-col-12 g-col-xxl-5">
                  <div className="grid dashboard-body-overview">
                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets title="Total Credit" number={dashboardData?.total_credits || 0} icon={<Coins01Icon height={48} width={48} color="#EDF5F6" strokeWidth="1.5" />} />
                      </div>
                    </div>
                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets title="Total Used Credits" number={dashboardData?.total_credits -
                          dashboardData?.available_credits || 0} icon={<Coins02Icon height={48} width={48} color="#FEF6EC" strokeWidth="1.5" />} />
                      </div>
                    </div>
                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets title="Reserved Credits" number={dashboardData?.reserved_credits || 0} icon={<CoinsSwapIcon height={48} width={48} color="#EDEFF6" strokeWidth="1.5" />} />
                      </div>
                    </div>

                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets title="Available Credits" number={dashboardData?.available_credits || 0} icon={<DatabaseIcon height={48} width={48} color="#EFF6ED" strokeWidth="1.5" />} />
                      </div>
                    </div>

                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets title="Total Recharge" number="50,000" icon={<UserGroup03Icon height={48} width={48} color="#F4F3FE" strokeWidth="1.5" />} />
                      </div>
                    </div>

                    <div className="g-col-6 g-col-xl-4 g-col-xxl-6">
                      <div className="bg-white common-shadow h-100 rounded-2">
                        <BaisicWidgets title="Recharge Count" number="05" icon={<WaterfallUp01Icon height={48} width={48} color="#FFF7F0" strokeWidth="1.5" />} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="g-col-12 g-col-xxl-7">
                  <div className="rounded-2 bg-white common-shadow grid gap-0">
                    <div className="px-3 py-4 g-col-8 border-end dashboard-body-overview">
                      <h5 className="text-capitalize">Campaigns</h5>
                      <CreditHistoryChart className="mt-3" />
                    </div>
                    <div className="px-3 py-4 g-col-4">
                      <div className="d-flex align-items-center h-100 flex-column justify-content-between gap-3">
                        <div className="custom-year-select">
                          <Form.Select
                            aria-label="Default select example"
                            className="shadow-none"
                          >
                            <option>2020</option>
                            <option value="1">2021</option>
                            <option value="2">2022</option>
                            <option value="3">2023</option>
                            <option value="4">2024</option>
                            <option value="5">2025</option>
                          </Form.Select>
                        </div>

                        <div className="d-flex align-items-center flex-column gap-3">
                          <div className="text-center total-campaign-text">
                            <h4 className="fw-medium purple">50,000</h4>
                            <p className="pt-1">Total Credit</p>
                          </div>
                          <div className="text-center total-campaign-text">
                            <h4 className="fw-medium orange">25,852</h4>
                            <p className="pt-1">Used credit</p>
                          </div>
                        </div>

                        <div className="d-flex flex-wrap align-items-center gap-3">
                          <div className="text-center d-flex flex-nowrap align-items-center gap-2 cursor-pointer total-campaign-text">
                            <span className="rounded-circle"></span>
                            <p white-space-nowrap>Credit</p>
                          </div>
                          <div className="text-center d-flex  flex-nowrap align-items-center gap-2 cursor-pointer total-campaign-text">
                            <span className="rounded-circle orange"></span>
                            <p className=" white-space-nowrap">Used credit</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="grid gap-4 advance-widgets-container">
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={dashboardData?.total_credits || 0}
            title="Total Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={
              dashboardData?.total_credits - dashboardData?.available_credits ||
              0
            }
            title="Total Used Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={dashboardData?.reserved_credits || 0}
            title="Total Reserved Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={dashboardData?.available_credits || 0}
            title="Available Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value="Expire On"
            title={moment(expiry_date).format("DD MMMM, YYYY")}
          />
        </div> */}
      </CommonLayout>
    </>
  );
};

export default Dashboard;
