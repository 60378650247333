import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function CreditHistoryChart({ className }) {
    const [state, setState] = useState({
        series: [
            {
                type: "column",
                name: "Credit",
                data: [11000, 20000, 25000, 7000, 9000],
            },
            {
                type: "column",
                name: "Used credit",
                data: [2500, 7500, 20000, 1800, 6000],
            },
        ],
        options: {
            chart: {
                type: "line",
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    borderRadius: 3,
                    borderRadiusApplication: "end",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: ["Jan", "Feb", "Mar", "Apr", "May"],
                labels: {
                    style: {
                        fontSize: "12px", // Default font size for x-axis
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: "12px", // Default font size for y-axis
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val + " thousands";
                    },
                },
            },
            colors: ["#7367F0", "#FF9F43"],
            grid: {
                show: true,
                borderColor: "#EAEAEA",
                strokeDashArray: 4,
            },
            legend: {
                show: false,
            },
        },
        chartHeight: 238, // Default chart height
    });

    const adjustChartDimensions = () => {
        const screenWidth = window.innerWidth;
        const legendFontSize = screenWidth > 1920 ? "18px" : "12px";
        const xAxisFontSize = screenWidth > 1920 ? "18px" : "12px";
        const yAxisFontSize = screenWidth > 1920 ? "18px" : "12px";
        const chartHeight = screenWidth > 1920 ? 265 : 238;

        setState((prevState) => ({
            ...prevState,
            options: {
                ...prevState.options,
                legend: {
                    ...prevState.options.legend,
                    fontSize: legendFontSize,
                },
                xaxis: {
                    ...prevState.options.xaxis,
                    labels: {
                        ...prevState.options.xaxis.labels,
                        style: {
                            ...prevState.options.xaxis.labels.style,
                            fontSize: xAxisFontSize,
                        },
                    },
                },
                yaxis: {
                    ...prevState.options.yaxis,
                    labels: {
                        ...prevState.options.yaxis.labels,
                        style: {
                            ...prevState.options.yaxis.labels.style,
                            fontSize: yAxisFontSize,
                        },
                    },
                },
            },
            chartHeight: chartHeight,
        }));
    };

    useEffect(() => {
        adjustChartDimensions();
        window.addEventListener("resize", adjustChartDimensions);

        return () => {
            window.removeEventListener("resize", adjustChartDimensions);
        };
    }, []);

    return (
        <div id="chart" className={className}>
            <ReactApexChart
                options={state.options}
                series={state.series}
                type="line"
                height={state.chartHeight}
            />
        </div>
    );
}
