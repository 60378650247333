import React, { useEffect, useState, useCallback } from "react";
import Input from "../../../components/input/index";
import CommomButton from "../../../components/common-button/index";
import CommonActionDropDown from "../../../components/common-action-dropdown";
import StatusChip from "../../../components/status-chip/index";
import CommonPagination from "../../../components/common-pagination/index";
import NavTabs from "../../../components/nav-tabs/index";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // OverlayTrigger,
  // Tooltip,
  Form,
  FormCheck,
  Toast,
  ToastContainer,
  Image,
  ButtonGroup,
} from "react-bootstrap";
import {fetchAllCampaigns, fetchEvents, addCampaign, deleteCampaign, cancelCampaign} from "../../../store/eventsSlice";
import { Container } from "react-bootstrap";
// import { MoreVertical } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
// import SortImage from "../../../sort.svg";
// import PaginationComponent from "../pagination/pagination";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useLoader } from "../../../context/LoaderContext";
import moment from "moment";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {Add01Icon, MoreVerticalIcon, CheckmarkBadge04Icon,Delete02Icon} from "../../../icons/icons";
import { CampaignsListTabs } from "../../../constants/pages/allCampaign/index";
import DeletePopup from "../../../components/delete-popup";
import axios from "axios";
import { recordsPerPageOptions } from "../campaings/common/Common";
// import { PencilSquare, Trash3, XSquare} from 'react-bootstrap-icons';


export default function List() {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const id = useSelector((state) => state.auth.user.id);
  const campaings = useSelector((state) => state.events?.allCampaings?.data);
  const customers = useSelector((state) => state.events?.events); // Fetch customers
  // const totalPages = useSelector(
  //   (state) => state.events?.allCampaings?.total_pages
  // );
  const total = useSelector((state) => state.events?.allCampaings?.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState({ value: 10, label: 10 });
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [selectedCustomer, setSelectedCustomer] = useState(null); // State for selected customer
  const [isWhatsappConnected, setIsWhatsappConnected] = useState(false); // State to check WhatsApp connection
  const [showWhatsappModal, setShowWhatsappModal] = useState(false); // Modal for WhatsApp connection
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  // const [hoveredRow, setHoveredRow] = useState(null);

  // console.log("campaingsList",campaings);
  

  const handleToggleDropdown = (dropdownId) => {
    setOpenDropdown((prev) => (prev === dropdownId ? null : dropdownId));
  };

  const navigate = useNavigate();

  const getEventsDetailsData = useCallback(() => {
    // setLoading(true);
    dispatch(
      fetchAllCampaigns({
        offset: currentPage,
        limit: limit?.value,
        search: searchTerm,
        status: activeTab,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, currentPage, limit?.value, searchTerm, setLoading, activeTab]);

  useEffect(() => {
    getEventsDetailsData();
  }, [getEventsDetailsData]);

  const fetchCustomers = useCallback(() => {
    dispatch(fetchEvents({ id }));
  }, [id, dispatch]);

  useEffect(() => {
    if (addModal) {
      fetchCustomers();
    }
  }, [addModal, fetchCustomers]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleAddClick = () => {
    // setAddModal(true);
    navigate(`/customers/new-campaign`);
    // navigate(`/all-campaigns/new-campaign`);
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
    // setSelectedCustomer(null); // Reset selected customer when modal closes
    setIsWhatsappConnected(false); // Reset WhatsApp connection state
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
    setCurrentPage(0);
  };

  const campaignValidation = Yup.object().shape({
    customer_id: Yup.string().required("Customer is required"),
    name: Yup.string().required("Campaign name is required"),
    sendOption: Yup.string().required("Send option is required"),
  });

  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: `${customer.name}`,
    isWhatsappConnected: customer.whatsapp_client?.is_active || false, // Assuming `whatsapp_client.is_active` exists
  }));

  const handleCustomerSelect = (option, setFieldValue) => {
    // console.log("OPTION : ", option);
    setSelectedCustomer(option);
    setIsWhatsappConnected(option.isWhatsappConnected);
    if (!option.isWhatsappConnected) {
      toggleAddModal();
      setShowWhatsappModal(true); // Show WhatsApp connection modal if not connected
    }
    setFieldValue("customer_id", option.value);
  };

  const handleConnectWhatsapp = () => {
    // console.log("Selected Customer : ", selectedCustomer);
    navigate(`/customers/${selectedCustomer.value}`);
    setShowWhatsappModal(false); // Close the modal
  };

  // const getColumnsForActiveTab = () => {
  //   const commonColumns = [
  //     {
  //       label: "Campaign Name",
  //       dataKey: "name",
  //     },
  //     {
  //       label: "Customer Name",
  //       dataKey: "client_event.name",
  //     },
  //     {
  //       label: "Customer Number",
  //       dataKey: "client_event.contact_number",
  //     },
  //     {
  //       label: "Send Type",
  //       dataKey: "template_type",
  //     },
  //     {
  //       label: "Status",
  //       dataKey: "status",
  //     },
  //   ];

  //   const dynamicColumns = [];

  //   switch (activeTab) {
  //     case "scheduled":
  //       dynamicColumns.push({
  //         label: "Scheduled Date",
  //         dataKey: "scheduled_date",
  //         format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
  //       });
  //       break;
  //     case "completed":
  //     case "in-process":
  //       dynamicColumns.push({
  //         label: "From Number",
  //         dataKey: "from_number",
  //       });
  //       break;
  //     case "failed":
  //       dynamicColumns.push({
  //         label: "Failed Reason",
  //         dataKey: "failed_reason",
  //       });
  //       dynamicColumns.push({
  //         label: "From Number",
  //         dataKey: "from_number",
  //       });
  //       break;
  //     default:
  //       break;
  //   }

  //   const remainingColumns = [
  //     {
  //       label: "Credits Used",
  //       dataKey: "total_credits",
  //     },
  //     {
  //       label: "Created At",
  //       dataKey: "created_at",
  //       format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
  //     },
  //   ];

  //   if (activeTab !== "pending" && activeTab !== "drafted") {
  //     remainingColumns.unshift({
  //       label: "Recipients",
  //       dataKey: "client_guests_not_test_count",
  //       // format: (value) =>
  //       //   Array.isArray(value)
  //       //     ? value.filter((guest) => !guest.is_test).length
  //       //     : 0,
  //     });
  //   }

  //   return [...commonColumns, ...dynamicColumns, ...remainingColumns];
  // };

  const getUserInitial = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n.charAt(0))
          .join("")
      : "UN";
  };

  const getColumnsForActiveTab = (activeTab) => {
    const commonColumns = [
      {
        label: "Campaign Name",
        dataKey: "campaign_customer_name", // Custom key for rendering complex fields
        render: (campaing) => (
          <div className="d-flex align-items-center gap-2">
            <div className="profile-icon">
              {getUserInitial(campaing?.client_event?.name)}
            </div>
            <span className="ms-1">
              <Link to="#">
                <p className="fw-medium text-capitalize">{campaing?.name}</p>
              </Link>
              {/* <small>{campaing?.client_event?.name}</small> */}
            </span>
         
          
            {/* {hoveredRow === campaing?.id && (
               <div className="d-flex align-items-center"
               style={{
                minWidth: "40px", // Set a fixed width for the container
                visibility: hoveredRow === campaing?.id ? "visible" : "hidden", // Control visibility
                height: "100%",
                transition: "visibility 0.3s ease",
              }}>
               {(campaing.status === "pending" || campaing.status === "drafted") && (
                 <div style={{width: "20px", height: "20px"}}>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>  
                   <PencilSquare onClick={(e) => {
                     e.stopPropagation();
                     handleSendMessageClick(campaing)
                   }}/>
                  </OverlayTrigger>
                 </div>
               )}
             {(campaing.status === "pending" || campaing.status === "drafted" || campaing.status === "scheduled" || campaing.status === "failed" || campaing.status === "cancel") && (
                <div style={{ width: "20px", height: "20px" }}>
                 <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
                   <Trash3 onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteCampaignClick(campaing)
                  }}/>
                  </OverlayTrigger>
                </div>
              )}
              {campaing.status === "in-process" && (
                <div style={{ marginLeft: "10px", width: "20px", height: "20px" }}>
                 <OverlayTrigger placement="top" overlay={<Tooltip>Cancel</Tooltip>}>
                    <XSquare onClick={(e) => {
                      e.stopPropagation();
                      handleCancelInProgressClick(campaing)
                    }}/>
                 </OverlayTrigger>
                </div>
              )}
            </div>
            )} */}
          </div>
        ),
      },
      {
        label: "Customer",
        dataKey: "client_event.contact_number",
        render: (campaing) => (
          <div className="d-flex align-items-center gap-2">
            <span className="ms-1">
              <Link to="#">
                <p className="fw-medium text-capitalize">
                  {campaing?.client_event?.name}
                </p>
              </Link>
              <small>{campaing?.client_event?.contact_number}</small>
            </span>
          </div>
        ),
      },
      {
        label: "Send Type",
        dataKey: "template_type",
        render: (campaing) =>
          campaing?.template_type === "text"
            ? "Only Message"
            : "With Invitation Card",
      },
      {
        label: "Status",
        dataKey: "status",
        render: (campaing) => (
          <StatusChip
            bg={
              campaing?.status === "completed"
                ? "chip-green"
                : campaing?.status === "in-process"
                ? "chip-blue"
                : campaing?.status === "pending"
                ? "chip-yellow"
                : campaing?.status === "drafted"
                ? "chip-blue"
                : campaing?.status === "scheduled"
                ? "chip-ornage"
                : "chip-red"
            }
            title={campaing?.status}
            className="text-capitalize"
          />
        ),
      },
    ];

    // Conditional columns based on the activeTab
    const tabSpecificColumns = [];

    if (activeTab === "failed") {
      tabSpecificColumns.push({
        label: "Failed Reason",
        dataKey: "failed_reason",
      });
      tabSpecificColumns.push({
        label: "From Number",
        dataKey: "from_number",
      });
    } else if (activeTab === "in-process" || activeTab === "completed") {
      tabSpecificColumns.push({
        label: "From Number",
        dataKey: "from_number",
      });
    } else if (activeTab === "scheduled") {
      tabSpecificColumns.push({
        label: "Scheduled Date",
        dataKey: "scheduled_date",
        format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
      });
    }

    const remainingColumns = [
      {
        label: "Recipients",
        dataKey: "client_guests_not_test_count",
      },
      {
        label: "Credits Used",
        dataKey: "total_credits",
        render : (item) => {
          const totalCredits = item?.total_credits;
          console.log("totalCredits",totalCredits);
          const testCredits = item?.client_guests_test_count;
          console.log("testCredits",testCredits);
          const usedCredits = totalCredits - testCredits;
          console.log("usedCredits",usedCredits);
          
          return <StatusChip pill bg={"chip-green"}  title={usedCredits} className="text-capitalize" /> 
        }
      },
      {
        label:"Test Credit",
        dataKey: "client_guests_test_count",
      },
      {
        label: "Created At",
        dataKey: "created_at",
        format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
      },
    ];

    return [...commonColumns, ...tabSpecificColumns, ...remainingColumns];
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handleSendMessageClick = (campaing) => {
    // Logic for sending messages (currently disabled)
    // console.log(campaing);
    if (campaing?.client_event?.whatsapp_client?.is_active) {
      navigate(`/customers/update-campaign/${campaing.id}`);
      // navigate(`/all-campaigns/update-campaign/${campaing.id}`);
    } else {
      setSnackbarMessage("Please Connect Whatsapp!!");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    }
  };

  const handleDeleteCampaignClick = (campaing) => {
    setSelectedCampaign(campaing); // Set the selected campaign to be deleted
    setShowDeleteConfirmModal(true); // Show the confirmation modal
  };

  const handleCancelScheduleClick = (campaing) => {
    setSelectedCampaign(campaing); // Set the selected campaign to be deleted
    setShowCancelConfirmModal(true); // Show the confirmation modal
  };

  const handleCancelInProgressClick = async (selectedCampaign) => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      console.log("bchdsbchkbdshcbhj");
      const response = await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/cancel-campaign`,
        {
          campaignId: selectedCampaign.id,
        }
      );

      if (response.data.success) {
        // alert("Campaign cancelled successfully.");
        // Optionally, refresh the campaign list or UI
        setSnackbarMessage("Campaign Canceled successfully");
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        getEventsDetailsData();
      }
    } catch (error) {
      setSnackbarMessage("Failed to cxcx cancel campaign");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowCancelConfirmModal(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      dispatch(deleteCampaign({ id: selectedCampaign.id }));
      setSnackbarMessage("Campaign deleted successfully");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
      getEventsDetailsData(); // Refresh the campaigns list
    } catch (error) {
      setSnackbarMessage("Failed to delete campaign");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowDeleteConfirmModal(false);
    }
  };

  const handleConfirmCancel = async () => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      dispatch(cancelCampaign({ id: selectedCampaign.id }));
      setSnackbarMessage("Campaign Canceled successfully");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
      getEventsDetailsData(); // Refresh the campaigns list
    } catch (error) {
      setSnackbarMessage("Failed to cancel campaign");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowCancelConfirmModal(false);
    }
  };

  // const ActionMenu = ({ campaing }) => {
  //   return (
  //     <Dropdown drop="up" className="position-static">
  //       <Dropdown.Toggle as={CustomToggle}>
  //         <MoreVertical size="15px" className="text-muted" />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu align={"end"}>
  //         {(campaing.status === "pending" || campaing.status === "drafted") && (
  //           <Dropdown.Item
  //             eventKey="1"
  //             onClick={() => handleSendMessageClick(campaing.id)}
  //           >
  //             Send Message
  //           </Dropdown.Item>
  //         )}
  //         <Dropdown.Item
  //           eventKey="2"
  //           onClick={() => {
  //             navigate(`/customers/view-campaign/${campaing.id}`);
  //           }}
  //         >
  //           View
  //         </Dropdown.Item>
  //         {(campaing.status === "pending" ||
  //           campaing.status === "drafted" ||
  //           campaing.status === "scheduled") && (
  //             <Dropdown.Item
  //               eventKey="3"
  //               // onClick={() => {
  //               //   navigate(`/customers/view-campaign/${campaing.id}`);
  //               // }}
  //               onClick={() => handleDeleteCampaignClick(campaing)}
  //             >
  //               Delete
  //             </Dropdown.Item>
  //           )}
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  const getCampaignActions = (campaing) => {
    let actions = [];

    // if (campaing.status === "scheduled") {
    //   actions.push({
    //     title: "Send Now",
    //     // handler: () => handleCancelScheduleClick(campaign),
    //   });
    // }

    actions.push({
      title: "View",
      handler: () => {
        navigate(`/all-campaigns/view-campaign/${campaing.id}`);
        // navigate(`/customers/view-campaign/${campaing.id}`);
      },
    });

    // Add "Send Message" action if the campaign status is pending or drafted
    if (campaing.status === "pending" || campaing.status === "drafted") {
      actions.unshift({
        title: "Edit",
        handler: () => handleSendMessageClick(campaing),
      });
    }

    // Add "Delete" action if the campaign status is pending, drafted,failed or scheduled
    if (
      campaing.status === "pending" ||
      campaing.status === "drafted" ||
      campaing.status === "scheduled" ||
      campaing.status === "failed" ||
      campaing.status === "cancel"
    ) {
      actions.push({
        title: "Delete",
        handler: () => handleDeleteCampaignClick(campaing),
      });
    }

    // Add "Cancel" action if the campaign status is scheduled, or in-process
    if (campaing.status === "scheduled") {
      actions.push({
        title: "Cancel Campaign",
        handler: () => handleCancelScheduleClick(campaing),
      });
    }

    if (campaing.status === "in-process") {
      actions.push({
        title: "Cancel",
        handler: () => handleCancelInProgressClick(campaing),
      });
      // actions.push({
      //   title: "Pause",
      //   handler: () => handlePauseInProgressClick(campaign),
      // });
    }

    return actions;
  };

  const handleChange = (newValue) => {
    // console.log("newValue",newValue);
    if (newValue) {
      // Update state with both value and label
      setLimit({ value: newValue.value, label: newValue.label });
    } else {
      // If the value is null (when user clears selection), set a default value
      setLimit({ value: 10, label: 10 }); // Set to default object
    }
  };


  console.log("All-campaings",campaings);
  

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={5000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        {/* Old Campaign List Code */}
        {/* <Row>
          <Col xl={12} lg={12} md={12} xs={12}>
            <div className="tablecontainer">
              <div className="mb-3 d-flex justify-content-end">
                <Button variant="primary" onClick={handleAddClick}>
                  New Campaign
                </Button>
              </div>

              <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs">
                <Tabs
                  defaultActiveKey="all"
                  className="pb-0 table-tabs-items"
                  id="uncontrolled-tab-example"
                  onSelect={handleTabSelect}
                >
                  <Tab eventKey="all" title="All"></Tab>
                  <Tab eventKey="pending" title="Pending"></Tab>
                  <Tab eventKey="drafted" title="Drafted"></Tab>
                  <Tab eventKey="scheduled" title="Scheduled"></Tab>
                  <Tab eventKey="in-process" title="In-process"></Tab>
                  <Tab eventKey="completed" title="Completed"></Tab>
                  <Tab eventKey="failed" title="Failed"></Tab>
                </Tabs>
              </div>

              <div className="overflow-auto">
                <Table className="text-nowrap text-start">
                  <thead className="table-light">
                    <tr>
                      {getColumnsForActiveTab().map((column) => (
                        <th key={column.dataKey}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>{column.label}</span>
                          </div>
                        </th>
                      ))}
                      <th>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Action</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaings?.length === 0 ? (
                      <tr>
                        <td className="text-center" colSpan="7">
                          <div className="mt-5 fw-bold fs-5">
                            No data available
                          </div>
                        </td>
                      </tr>
                    ) : (
                      campaings?.map((campaing) => (
                        <tr key={campaing?.id}>
                          {getColumnsForActiveTab().map((column) => (
                            <td
                              key={column.dataKey}
                              className="text-capitalize"
                            >
                              {column.format
                                ? column.format(campaing[column.dataKey])
                                : column.dataKey.includes(".")
                                  ? column.dataKey
                                    .split(".")
                                    .reduce((o, i) => (o ? o[i] : ""), campaing)
                                  : column.dataKey === "total_credits"
                                    ? campaing[column.dataKey] || 0
                                    : column.dataKey === "template_type"
                                      ? campaing[column.dataKey] === "text"
                                        ? "Only Message"
                                        : "With Invitation Card"
                                      : campaing[column.dataKey]}
                            </td>
                          ))}
                          <td>
                            <ActionMenu campaing={campaing} />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>

                {totalPages > 1 ? (
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row> */}

        {/* New Campaign List Code */}
        <div className="common-tab-spacing">
          <NavTabs
            defaultActiveKey="all"
            id="uncontrolled-tab-example"
            onSelect={handleTabSelect}
            tabsData={CampaignsListTabs}
          />
        </div>

        <div className="bg-white rounded-2 overflow-hidden common-shadow">
          <div className="coomon-table-action-head d-flex align-items-center justify-content-between gap-4">
            <Input
              type="search"
              value={searchTerm}
              placeholder="Search Campaign Name"
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(0);
              }}
              className="shadow-none base-search"
            />

            <CommomButton
              onClick={handleAddClick}
              icon={
                <Add01Icon
                  width={16}
                  height={16}
                  color="#ffffff"
                  strokeWidth="2"
                />
              }
              title="New Campaign"
              className="btn-primary common-one-shadow"
            />
          </div>

          <Table responsive className="common-table">
            <thead>
              <tr>
                {getColumnsForActiveTab(activeTab).map((column) => (
                  <th key={column.dataKey}>
                    <p>{column.label}</p>
                  </th>
                ))}
                <th>
                  <p>Actions</p>
                </th>
              </tr>
            </thead>

            <tbody>
              {campaings?.length === 0 ? (
                <tr>
                  <td
                    className="text-center"
                    colSpan={getColumnsForActiveTab(activeTab).length + 1}
                  >
                    <div className=" text-center w-100 blank-table-view">
                      <Image
                        src="/assets/images/component-common/blank-table.png"
                        alt="Blank-Table-Data-Image"
                        className="img-fluid"
                      />
                      <div className="mt-3">
                        <h5 className="fw-medium">Couldn't find any data.</h5>
                        <p className="pt-2 mt-1">
                          This report does not contain any points. 
                          {/* Try to change
                          your filter and try again. */}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                campaings?.map((campaing, i) => (
                  <tr
                    key={campaing?.id}
                    onClick={() =>
                      // navigate(`/customers/view-campaign/${campaing.id}`)
                      navigate(`/all-campaigns/view-campaign/${campaing.id}`,{state: { campaing }})
                    }
                    className="data-row"
                    style={{ cursor: "pointer" }}
                    // onMouseEnter={() => setHoveredRow(campaing?.id)} // Track hover
                    // onMouseLeave={() => setHoveredRow(null)} // Reset on leave
                  >
                    {getColumnsForActiveTab(activeTab).map((column) => (
                      <td key={column.dataKey}>
                        {column.render
                          ? column.render(campaing)
                          : column.format
                          ? column.format(campaing[column.dataKey])
                          : column.dataKey.includes(".")
                          ? column.dataKey
                              .split(".")
                              .reduce((o, i) => (o ? o[i] : ""), campaing)
                          : campaing[column.dataKey]}
                      </td>
                    ))}
                    {/* Action dropdown */}
                    <td onClick={(e) => e.stopPropagation()}>
                      <CommonActionDropDown
                        as={ButtonGroup}
                        id="dropdown-basic"
                        icon={
                          <MoreVerticalIcon
                            width={20}
                            height={20}
                            color="#6D6B77"
                            strokeWidth="4"
                          />
                        }
                        actions={getCampaignActions(campaing)}
                        show={openDropdown === i}
                        onToggle={() => handleToggleDropdown(i)}
                        className="action-menu"
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>

          <div className="common-table-foot-new mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
            <p className="custom-table-infotext ">
              Displaying {currentPage * limit?.value + 1} to{" "}
              {Math.min((currentPage + 1) * limit?.value, total)} of {total}{" "}
              Campaigns
            </p>
            <div className="d-flex gap-4">
              {/* {totalPages > 1 ? (
                <> */}
                {campaings?.length !== 0 && (
                  <>
                  <CreatableSelect
                    isClearable
                    options={recordsPerPageOptions}
                    value={limit}
                    onChange={handleChange}
                    placeholder="Select or create"
                    menuPortalTarget={document.body} // Render the menu at the body level
                    menuPlacement="top" // Display the options above the input
                    className="custom-pagination-visible-item"
                    styles={{
                      control: (base) => ({
                        ...base,
                        width: "170px", // Fixed width for the input field
                      }),
                      menu: (base) => ({
                        ...base,
                        width: "170px", // Fixed width for the dropdown menu
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999, // Ensure it appears above everything
                      }),
                    }}
                  />

                  <CommonPagination
                    totalItems={total}
                    itemsPerPage={limit?.value}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                  </>
                )}
                {/* </>
              ) : null}  */}
            </div>
          </div>
        </div>
      </Container>

      <Modal
        show={addModal}
        onHide={() => {
          setSelectedCustomer(null);
          toggleAddModal();
        }}
        centered
      >
        <ModalHeader closeButton>
          <h3 className="text-primary mb-0">New Campaign</h3>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              customer_id: "",
              name: "",
              sendOption: "invitation",
            }}
            validationSchema={campaignValidation}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setErrors }
            ) => {
              setIsSubmitting(true);
              const formData = new FormData();
              formData.append("client_id", values.customer_id);
              formData.append("_method", "POST");
              formData.append("name", values.name);

              if (values.sendOption === "message") {
                formData.append("template_type", "text");
              }

              dispatch(addCampaign(formData))
                .then((res) => {
                  if (res.payload.success) {
                    dispatch(
                      fetchAllCampaigns({
                        offset: currentPage,
                        limit,
                        search: searchTerm,
                        status: activeTab,
                      })
                    );
                    resetForm();
                    setSelectedCustomer(null);
                    toggleAddModal();
                    navigate(
                      `/customers/${values.customer_id}/add-campaign/${res.payload.data.id}`
                    );
                  } else {
                    setErrors(res.payload.data);
                  }
                })
                .catch((error) => {
                  console.error("Failed to add campaign:", error);
                })
                .finally(() => {
                  setIsSubmitting(false);
                });
            }}
          >
            {({
              errors,
              touched,
              handleChange,

              submitForm,
              values,
              setFieldValue,
            }) => (
              <>
                <Form.Group controlId="customerSelect">
                  <Form.Label>Select a Customer</Form.Label>
                  <Select
                    options={customerOptions}
                    name="customer_id"
                    value={customerOptions.find(
                      (option) => option.value === values.customer_id
                    )}
                    onChange={(option) =>
                      handleCustomerSelect(option, setFieldValue)
                    }
                    className={`${
                      errors.customer_id && touched.customer_id
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="-- Select a Customer --"
                  />
                  {errors.customer_id && touched.customer_id && (
                    <div className="invalid-feedback">{errors.customer_id}</div>
                  )}
                </Form.Group>
                <br />

                {isWhatsappConnected ? (
                  <>
                    <div className="d-flex gap-3 mb-3">
                      <FormCheck
                        type="radio"
                        label="Send with Document"
                        name="sendOption"
                        id="sendInvitation"
                        value="invitation"
                        checked={values.sendOption === "invitation"}
                        onChange={handleChange}
                        className={`${
                          errors.sendOption && touched.sendOption
                            ? "is-invalid"
                            : ""
                        } `}
                      />
                      <FormCheck
                        type="radio"
                        label="Only Message"
                        name="sendOption"
                        id="sendMessage"
                        value="message"
                        checked={values.sendOption === "message"}
                        onChange={handleChange}
                        className={`${
                          errors.sendOption && touched.sendOption
                            ? "is-invalid"
                            : ""
                        }`}
                      />

                      {errors.sendOption && touched.sendOption ? (
                        <div className="invalid-feedback">
                          {errors.sendOption}
                        </div>
                      ) : null}
                    </div>
                    <Field
                      name="name"
                      type="text"
                      className={`form-control ${
                        errors.name && touched.name ? "is-invalid" : ""
                      }`}
                      placeholder="Enter campaign name"
                      onChange={handleChange}
                      disabled={!isWhatsappConnected} // Disable the input if WhatsApp is not connected
                    />
                    {errors.name && touched.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                    <br />
                  </>
                ) : null}
                <ModalFooter className="d-flex justify-content-end">
                  <div className="d-flex align-items-center gap-3">
                    <Button
                      variant="primary"
                      onClick={submitForm}
                      disabled={isSubmitting || !isWhatsappConnected} // Disable the Add button if WhatsApp is not connected
                    >
                      {isSubmitting ? "Adding..." : "Add"}
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setSelectedCustomer();
                        toggleAddModal();
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </ModalFooter>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>

      {/* WhatsApp Connection Modal */}
      <Modal
        show={showWhatsappModal}
        onHide={() => setShowWhatsappModal(false)}
        centered
      >
        <ModalHeader closeButton>
          <h3 className="text-danger mb-0">WhatsApp Not Connected</h3>
        </ModalHeader>
        <ModalBody>
          <p>
            The selected customer does not have WhatsApp connected. Please
            connect WhatsApp before adding a campaign.
          </p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button variant="primary" onClick={handleConnectWhatsapp}>
            Connect WhatsApp
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowWhatsappModal(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* Delete Modal */}
      {/* <Modal
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this campaign?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmDelete}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal> */}
      <DeletePopup
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        icon={
          <Delete02Icon
            width={48}
            height={48}
            color="#ff4c51"
            strokeWidth="1.5"
          />
        }
        title="Confirm Delete?"
        content="Are you sure you want to delete this campaign?"
        removeTitle="Cancel"
        onClickSave={handleConfirmDelete}
        disabled={isSubmitting}
        saveTitle={isSubmitting ? "Deleting..." : "Delete"}
      />

      <DeletePopup
        show={showCancelConfirmModal}
        onHide={() => setShowCancelConfirmModal(false)}
        icon={
          <Delete02Icon
            width={48}
            height={48}
            color="#ff4c51"
            strokeWidth="1.5"
          />
        }
        title="Confirm Schedule Cancel?"
        content="Are you sure you want to Draft this campaign?"
        removeTitle="Cancel"
        onClickSave={handleConfirmCancel}
        saveTitle={isSubmitting ? "Drafting..." : "Draft"}
      />
    </>
  );
}
