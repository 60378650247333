import React, { useCallback, useEffect } from "react";
import CommonFormLabel from "../../../../components/common-form-label/index";
// import ValidationFeedback from "../../../../components/validation-feedback/index";
import Input from "../../../../components/input/index";
import RadioButton from "../../../../components/radio-button/index";
// import CommomButton from "../../../../components/common-button/index";
import SelectBox from "../../../../components/select-box/index";
// import axios from "axios";
// import { Formik } from "formik";
// import { Button } from "react-bootstrap";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  // addCampaign,
  // fetchEventDetail,
  fetchEvents,
} from "../../../../store/eventsSlice";
import {
  DocumentAttachmentIcon,
  // Note04Icon,
  TextFontIcon,
  // ArrowRight02Icon
} from "../../../../icons/icons";
// import * as Yup from "yup";

export default function CampaignsType({
  campaingDetails,
  initialCampaignState,
  setInitialCampaignState,
  clientFromQuery,
}) {
  const dispatch = useDispatch();

  const customers = useSelector((state) =>
    state.events?.events.filter((evt) => evt?.whatsapp_client?.is_active)
  );

  // const [isSubmitting, setIsSubmitting] = useState(false);

  // const [isScanSuccessful, setIsScanSuccessful] = useState(false);

  // const [showSnackbar, setShowSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarVariant, setSnackbarVariant] = useState("toast-success");

  // const [currentPage, setCurrentPage] = useState(0);
  // const [limit] = useState(10);

  // const [qrCode, setQrCode] = useState(null);

  const authId = useSelector((state) => state.auth.user.id);

  // const { id, client_id } = useParams();

  const fetchCustomers = useCallback(() => {
    dispatch(fetchEvents({ id: authId }));
  }, [authId, dispatch]);

  useEffect(() => {
    if (campaingDetails) {
      fetchCustomers();
    }
  }, [campaingDetails, fetchCustomers]);

  // const [initialCampaignState, setInitialCampaignState] = useState({
  //   name: campaingDetails?.name || "",
  //   sendOption: "invitation",
  //   customer: campaingDetails?.client_event?.name
  // });

  // console.log("Initial value :::::::: ", initialCampaignState);

  // const campaignValidation = Yup.object().shape({
  //   name: Yup.string().required("Name is required"),
  //   sendOption: Yup.string().required("Send option is required"),
  // });

  // const handleConnect = async (mobile_number) => {
  //   try {
  //     setIsScanSuccessful(!isScanSuccessful);
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/connect`,
  //       {
  //         clientId: id,
  //         wsId: `ws_connect_${id}`,
  //         userId: id,
  //       }
  //     );
  //     // setQrCode(response.data.qrString);
  //   } catch (error) {
  //     console.error("Error connecting to WhatsApp:", error);
  //     // setSnackbarMessage("Failed to connect to WhatsApp");
  //     // setSnackbarVariant("toast-danger");
  //     // setShowSnackbar(true);
  //   }
  // };

  console.log("campaingDetails",campaingDetails);
  

  return (
    <div className="bg-white rounded-2 overflow-hidden common-shadow  mb-4">
      {/* <Formik
        initialValues={initialCampaignState}
        validationSchema={campaignValidation}
        onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
          setIsSubmitting(true);
          const formData = new FormData();
          formData.append("client_id", client_id);
          formData.append("_method", "POST");
          formData.append("name", values.name);

          if (values.sendOption === "message") {
            formData.append("template_type", "text");
          }

          dispatch(addCampaign(formData))
            .then((res) => {
              if (res.payload.success) {
                // setSnackbarMessage(res.payload.message);
                // setSnackbarVariant("toast-success");
                // setShowSnackbar(true);
                resetForm();
              } else {
                // setSnackbarMessage(res.payload.message);
                // setSnackbarVariant("toast-danger");
                // setShowSnackbar(true);
                setErrors(res.payload.data);
              }
            })
            .catch((error) => {
              // setSnackbarMessage("Failed to add campaign: " + error.message);
              // setSnackbarVariant("toast-danger");
              // setShowSnackbar(true);
            })
            .finally(() => {
              setIsSubmitting(false);
            });
        }} 
      > */}
      {/* {({ errors, touched, handleChange, submitForm, values }) => ( */}
      <>
        <div className="campaigns-type-content">
          <div className="campaigns-type-header mb-4">
            <h5 className="fw-medium mb-1">Create Campaign</h5>
            <p>Select method to run campaign</p>
          </div>

          <div className="grid mb-4">
            <div className="g-col-5">
              <CommonFormLabel title="Select Customer" />
              <SelectBox
                name="customer"
                value={initialCampaignState?.customer}
                staticOption="Choose a Select Customer"
                data={customers.map((customer) => ({
                  value: customer.id,
                  label: customer.name,
                }))}
                disabled={
                  !!campaingDetails?.client_event?.id || !!clientFromQuery
                } // Disable if customer is pre-selected
                // onChange={handleChange}
                onChange={(e) => {
                  setInitialCampaignState({
                    ...initialCampaignState,
                    customer: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="pt-2 d-flex align-items-center justify-content-start gap-4 mb-4">
            <div className="d-flex align-items-center gap-3">
              <RadioButton
                name="sendOption"
                id="sendInvitation"
                value="invitation"
                checked={initialCampaignState.sendOption === "invitation"}
                onChange={(e) => {
                  // console.log("Radio::::::::", e.target.value);
                  setInitialCampaignState({
                    ...initialCampaignState,
                    sendOption: "invitation",
                  });
                }}
                className={`md-radio `}
                // disabled={campaingDetails && campaingDetails?.template_type}
              />
              <div className="d-flex align-items-center gap-2 switch-tab">
                <div className="rounded-2 d-flex align-items-center justify-content-center icon">
                  <DocumentAttachmentIcon
                    width={24}
                    height={24}
                    color="#ffffff"
                    strokeWidth="2"
                  />
                </div>
                <p className="fw-meidum">Send with Document</p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3">
              <RadioButton
                name="sendOption"
                id="sendMessage"
                value="message"
                checked={initialCampaignState.sendOption === "message"}
                onChange={(e) => {
                  // console.log("Radio::::::::", e.target.value);
                  setInitialCampaignState({
                    ...initialCampaignState,
                    sendOption: "message",
                  });
                }}
                className={`md-radio`}
                // disabled={campaingDetails && campaingDetails?.template_type}
              />
              <div className="d-flex align-items-center gap-2 switch-tab">
                <div className="rounded-2 d-flex align-items-center justify-content-center icon">
                  <TextFontIcon
                    width={24}
                    height={24}
                    color="#ffffff"
                    strokeWidth="2"
                  />
                </div>
                <p className="fw-meidum">Only Message</p>
              </div>
            </div>
          </div>

          <div className="grid mb-4">
            <div className="g-col-5">
              <CommonFormLabel title="Campaigns Name" />
              <Input
                name="name"
                value={initialCampaignState.name}
                type="text"
                placeholder="Enter Campaigns Name"
                onChange={(e) => {
                  setInitialCampaignState({
                    ...initialCampaignState,
                    name: e.target.value,
                  });
                }}
                className={`shadow-none 
                      `}
              />

              {/* {errors.name && touched.name ? (
                    <ValidationFeedback title={errors.name} />
                  ) : null} */}
            </div>
          </div>
        </div>

        {/* <div className='mt-4 d-flex align-items-center justify-content-end'>
                        <CommomButton
                            title={isSubmitting ? "Adding..." : "Next"}
                            type="submit" icon={<ArrowRight02Icon width={17} height={17} color="#ffffff" strokeWidth="2.2" />}
                            onClick={submitForm} disabled={isSubmitting}
                            className="btn-primary primary-shadow "
                            subClassName="flex-row-reverse gap-2"
                        />
                    </div> */}
      </>
      {/* )}
      </Formik> */}
    </div>
  );
}
