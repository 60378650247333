import React, { Fragment, useEffect, useRef, useState } from "react";
import CommomButton from "../../../../components/common-button/index";
import CommonFormLabel from "../../../../components/common-form-label";
import Input from "../../../../components/input/index";
import ValidationFeedback from "../../../../components/validation-feedback/index";
// import {
//   Bold,
//   Italic,
//   // ArrowLeft,
//   // ArrowRight,
//   // Smile,
//   Calendar,
//   // Send,
// } from "react-feather";
import {
  Toast,
  Button,
  // Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  // Row,
  ToastContainer,
} from "react-bootstrap";
import {
  // Card,
  // Input,
  Label,
} from "reactstrap";
// import { useNavigate } from "react-router-dom";
import moment from "moment";
import EmojiPickerWrapper from "../common/EmojiPickerWrapper";
import "../styleModule.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import { convertBase64ToFile, pdfToBase64File } from "../common/Common";
import {
  CheckmarkBadge04Icon,
  TextBlodIcon,
  TextItalicIcon,
  TextStrikeThroughIcon,
} from "../../../../icons/icons";
// import { Bold, Italic } from "react-feather";
import Checkbox from "../../../../components/checkbox";

// import { notification } from "./common/notification";

const InsertMessage = ({
  tableData,
  fileType,
  stepper,
  csvKeys,
  messageData,
  setMessageData,
  formData,
  setFormData,
  formError,
  setFormError,
  inputdata,
  file,
  handleTestClick,
  isTestOpen,
  setIsTestOpen,
  setPos,
  pos,
  campaingDetails,
  setPdfName,
  pdfName,
  isAllowSchedule,
  setIsAllowSchedule,
  templateType
}) => {
  const inputRef = useRef(null);

  const { campaignId: id } = useParams();
  const [selection1, setSelection1] = useState([]);
  const [cur, setCur] = useState(0);
  const [pdfCur, setPdfCur] = useState(0);
  const [modalSchedule, setModalSchedule] = useState(false);
  const [selection, setSelection] = useState();
 
  const toggleSchedule = () => {
    delete formError.schedule;
    setModalSchedule(!modalSchedule);
  };
  const expiry_date = useSelector((state) => state.auth.user.expiry_date);
  const company_id = useSelector((state) => state.auth.user.company_id);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");

  useEffect(() => {
    if (!selection) return;
    const { start, end } = selection;
    console.log(start, end); 
    inputRef?.current?.focus();
    inputRef?.current?.setSelectionRange(start, end);
  }, [selection]);

  const addVariableInMessage = (variable,e) => {
      setMessageData(
        [
          messageData?.slice(0, cur),
          "[[" + variable + "]]",
          messageData?.slice(cur),
        ].join("")
      );
  
      setCur(cur + `[[${variable}]]`.length);
      setSelection({
        start: cur + `[[${variable}]]`.length,
        end: cur + `[[${variable}]]`.length,
      });
  };

  const addVariableInPdfName = (variable) => {
    setPdfName(
      [
        pdfName?.slice(0, pdfCur),
        "[[" + variable + "]]",
        pdfName?.slice(pdfCur),
      ].join("")
    );

    setPdfCur(pdfCur + `[[${variable}]]`.length);
    // setPdfSelection({
    //   start: pdfCur + `[[${variable}]]`.length,
    //   end: pdfCur + `[[${variable}]]`.length,
    // });
  };

  const onEmojiClick = (e, emojiObject) => {
    setMessageData(
      [messageData.slice(0, cur), e.emoji, messageData.slice(cur)].join("")
    );
    setCur(cur + 2);
    setSelection({
      start: cur + 2,
      end: cur + 2,
    });
  };

  const textFormatting = (type) => {
    if (messageData !== "" && selection1.length > 0) {
      let newMessage = "";
      if (type === "b") {
        newMessage = [
          messageData.slice(0, selection1[0]),
          "*",
          messageData.slice(selection1[0], selection1[1]),
          "*",
          messageData.slice(selection1[1]),
        ].join("");
      } else if (type === "i") {
        newMessage = [
          messageData.slice(0, selection1[0]),
          "_",
          messageData.slice(selection1[0], selection1[1]),
          "_",
          messageData.slice(selection1[1]),
        ].join("");
      } else if (type === "s") {
        newMessage = [
          messageData.slice(0, selection1[0]),
          "~",
          messageData.slice(selection1[0], selection1[1]),
          "~",
          messageData.slice(selection1[1]),
        ].join("");
      }
      setMessageData(newMessage);
      setSelection1([]);
      setCur(newMessage?.length);
    }
  };

  const currentSelection = (e) => {
    setSelection1([e.target.selectionStart, e.target.selectionEnd]);
  };

  const handleChangeForMessage = (e) => {
    e.preventDefault();
    setMessageData(e.target.value);
  };

  const handlePdfNameChange = (e) => {
    e.preventDefault();
    setPdfName(e.target.value || "");
    // console.log("PDF MNASME :::::::: ", pdfName);
  };

  const onBlur = (e) => {
    setCur(e.target.selectionStart);
  };

  const [testMobile, setTestMobile] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggleTestModal = () => {
    setTestMobile("");
    setIsSubmitting(false);
    setIsTestOpen(!isTestOpen);
  };

  const sendTestMessage = async () => {
    if (
      !testMobile ||
      (!messageData && campaingDetails?.template_type === "text")
    ) {
      setSnackbarMessage(
        !testMobile
          ? "Please enter Mobile number."
          : "Please enter message to send."
      );
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      setTestMobile("");
      setIsSubmitting(false);
      toggleTestModal();
    } else {
      setIsSubmitting(true);

      try {
        let media = [];
        if (file) {
          const pdfBytes = await changeFont(tableData[0]);
          if (fileType === "application/pdf") {
            media = new File([pdfBytes], `${tableData[0].name}.pdf`, {
              type: "application/pdf;charset=utf-8",
            });
          } else {
            const dataURL = await pdfToBase64File(pdfBytes, fileType);
            media = await convertBase64ToFile(dataURL);
          }
        }
        let newMessage = messageData;
        csvKeys.forEach((item) => {
          newMessage = newMessage.replaceAll(
            `[[${item}]]`,
            tableData[0][item].replaceAll(/;/g, ",").trim()
          );
        });

        let new_pdf_name = pdfName;

        csvKeys.forEach((item) => {
          new_pdf_name = new_pdf_name.replaceAll(
            `[[${item}]]`,
            tableData[0][item].replaceAll(/;/g, ",").trim()
          );
        });
        let request = new FormData();

        // console.log("CLientId :::::::::::::", campaingDetails);

        request.append("campaign_id", id);
        request.append("clientId", campaingDetails?.client_event?.id);
        request.append("company_id", company_id);
        request.append("message", newMessage);
        request.append(
          "delay",
          formData?.delaySecond ? formData?.delaySecond : 0
        );
        request.append("numbers", testMobile);
        request.append("name", "Test Message");
        request.append(
          "file_name",
          `Test${
            fileType === "application/pdf"
              ? ".pdf"
              : fileType === "image/png"
              ? ".png"
              : ".jpeg"
          }`
        );
        request.append("media", media);
        request.append("pdf_name", new_pdf_name || "Invitation");

        let response = await axios.post(
          `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/send-test-message`,
          request
        );

        // console.log("RESPOSNSE: ", response);

        setSnackbarMessage(response.data.message);
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        setTestMobile("");
        setIsSubmitting(false);
        toggleTestModal();
        setTestMobile("");
      } catch (error) {
        console.log("Error Testing :::::: ", error);
        setSnackbarMessage(error.response.data.error);
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
        setTestMobile("");
        setIsSubmitting(false);
        toggleTestModal();
      }
    }
  };

  const changeFont = async (data) => {
    const existingPdfBytes = await fetch(URL.createObjectURL(file)).then(
      (res) => res.arrayBuffer()
    );
    const pdfDocs1 = await PDFDocument.load(existingPdfBytes, {
      ignoreEncryption: true
    });
    const pages = pdfDocs1.getPages();
    await pdfDocs1.registerFontkit(fontkit);
    let embeddedFonts = [];
    for (let key in pos) {
      const fontObject = pos[key];
      const fontBytes = await fetch(fontObject.fontFile).then((res) =>
        res.arrayBuffer()
      );
      const embeddedFont = await pdfDocs1.embedFont(fontBytes, {
        subset: true,
      });
      embeddedFonts.push(embeddedFont);
    }
    let index = 0;
    for (let key in pos) {
      const page = pages[pos[key].page - 1];
      const { height } = page.getSize();
      const textHeight = embeddedFonts[index].sizeAtHeight(pos[key]?.size);
      let x = pos[key].x;
      page.drawText(data[key].replaceAll(/;/g, ","), {
        x: x,
        y:
          height -
          (pos[key].y +
            textHeight -
            (textHeight >= 46
              ? 7
              : textHeight >= 30
              ? 6
              : textHeight >= 12
              ? 3
              : 1)),
        size: pos[key]?.size,
        font: embeddedFonts[index],
        color: rgb(
          pos[key]?.color[0] / 255,
          pos[key]?.color[1] / 255,
          pos[key]?.color[2] / 255
        ),
      });
      index++;
    }
    const pdfBytes = await pdfDocs1.save();
    return pdfBytes;
  };

  // console.log("templateTypeInsertMessage",templateType);

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <div className="bg-white rounded-2 common-shadow p-4 mb-4">
        <div className="add-message-content">
          <div className="d-flex laign-align-items-start justify-content-between gap-4 mb-4">
            <div>
              <h5 className="fw-medium text-capitalize">Add Message</h5>
              <p>Enter your message which you want to send to your Invitees</p>
            </div>
            <CommomButton
              title="Clear Data"
              onClick={() => {
                setMessageData("");
              }}
              type="reset"
              className="btn-outline-danger bg-transparent"
            />
          </div>

          <div className="mb-4">
            <CommonFormLabel title="Enter Message" />
            <Input
              as="textarea"
              className="shadow-none addmsg-textarea"
              id="message"
              ref={inputRef}
              placeholder=" Please Enter Your Message"
              name="textMessage"
              onBlur={onBlur}
              value={messageData}
              onChange={handleChangeForMessage}
              onSelect={currentSelection}
            />
          </div>
          {/* <div> */}
          <div className="d-flex align-items-center justify-content-between gap-3">
            <div>
              {csvKeys &&
                Array.from(csvKeys).map((rows, i) => {
                  return (
                    rows !== "number" && (
                      <CommomButton
                        key={i}
                        title={`[[${rows}]]`}
                        className="btn-outline-primary bg-transparent mx-1 my-1"
                        onClick={(e) => {
                          e.preventDefault();
                          addVariableInMessage(rows,e);
                          // Remove focus from the button after click
                          e.target.blur();
                        }}
                      />
                    )
                  );
                })}
            </div>
            <div className="d-flex justify-content-end align-items-center emogis-icons">
              <EmojiPickerWrapper onEmojiClick={onEmojiClick} />

              <TextBlodIcon
                height={18}
                width={18}
                strokeWidth="2"
                color="#444050"
                onClick={() => {
                  textFormatting("b");
                }}
                className="cursor-pointer"
              />

              <TextItalicIcon
                height={18}
                width={18}
                strokeWidth="2"
                color="#444050"
                onClick={() => {
                  textFormatting("i");
                }}
                className="cursor-pointer"
              />

              <TextStrikeThroughIcon
                height={18}
                width={18}
                strokeWidth="2"
                color="#444050"
                onClick={() => {
                  textFormatting("s");
                }}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>

     {(templateType === "pdf" || templateType === "image") && (
        <div className="bg-white rounded-2 common-shadow p-4 mb-4">
        <div className="add-message-content">
          {/* <div className="mb-4">
            <h5 className="fw-medium text-capitalize">Delay Second</h5>
          </div> */}
          {/* <div className="grid gap-4 mb-4">
            <div className="g-col-2">
              <CommonFormLabel title="From" />
              <Input
                type="text"
                className="shadow-none "
                min={0}
                max={10}
                maxLength={2}
                onChange={inputdata}
                value={formData?.delayFromSecond}
                name="delayFromSecond"
              />
              <ValidationFeedback title={formError?.delayFromSecond} />
            </div>
            <div className="g-col-2">
              <CommonFormLabel title="To" />
              <Input
                type="text"
                className="shadow-none"
                min={0}
                max={10}
                maxLength={2}
                onChange={inputdata}
                value={formData?.delayToSecond}
                name="delayToSecond"
              />
              <ValidationFeedback title={formError?.delayToSecond} />
            </div>
          </div> */}
          {/* <div className="mb-4">
            <h5 className="fw-medium text-capitalize">Schedule Message</h5>
          </div> */}
          {/* <div className="grid mb-4">
            <div className="g-col-4">
              <CommonFormLabel title="Select Date" />
              <Input
                  type="datetime-local"
                  className="shadow-none date-input"
                  placeholder="Select Date"
                  id="schedule-time"
                  onChange={inputdata}
                  value={formData?.schedule || moment().format("YYYY-MM-DDTHH:mm")}
                  name="schedule"
                  min={moment().format("YYYY-MM-DDTHH:mm")}
                  max={moment(expiry_date).format("YYYY-MM-DDTHH:mm")}
                />
              <ValidationFeedback title={formError?.schedule} />
            </div>
          </div> */}

          <div className="mb-4">
            <h5 className="fw-medium text-capitalize">Customize PDF Name</h5>
          </div>
          <div className="grid">
            <div className="g-col-6">
              <div>
                <CommonFormLabel title="PDF Name" />
                <Input
                  type="text"
                  className="shadow-none date-input"
                  placeholder="Customized PDF Name"
                  id="schedule-time"
                  onChange={handlePdfNameChange}
                  value={pdfName}
                />
                <div className="d-flex align-items-center gap-2 mt-2 mb-4">
                  <p>
                    Your Default PDF name will be
                    <span className="fw-semibold">{" "}Invitation</span>
                  </p>
                </div>
                {csvKeys &&
                  Array.from(csvKeys).map((rows, i) => {
                    return (
                      rows === "name" && (
                        <CommomButton
                          key={i}
                          title={`[[${rows}]]`}
                          className="btn-outline-primary bg-transparent"
                          onClick={(e) => {
                            e.preventDefault();
                            addVariableInPdfName(rows);
                            // Remove focus from the button after click
                            e.target.blur();
                          }}
                        />
                      )
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        </div>
     )}
     

      <div className="bg-white rounded-2 common-shadow p-4 mb-4">
        <div className="add-message-content">
          <div className="mb-4">
            <h5 className="fw-medium text-capitalize">Delay Second</h5>
          </div>
          <div className="grid gap-4 mb-4">
            <div className="g-col-2">
              <CommonFormLabel title="From" />
              <Input
                type="number"
                className="shadow-none "
                min={0}
                max={10}
                maxLength={2}
                onChange={inputdata}
                value={formData?.delayFromSecond}
                name="delayFromSecond"
              />
              <ValidationFeedback title={formError?.delayFromSecond} />
            </div>
            <div className="g-col-2">
              <CommonFormLabel title="To" />
              <Input
                type="number"
                className="shadow-none"
                min={0}
                max={10}
                maxLength={2}
                onChange={inputdata}
                value={formData?.delayToSecond}
                name="delayToSecond"
              />
              <ValidationFeedback title={formError?.delayToSecond} />
            </div>
          </div>
          {/* <div className="mb-4">
            <h5 className="fw-medium text-capitalize">Schedule Message</h5>
          </div>
          <div className="grid mb-4">
            <div className="g-col-4">
              <CommonFormLabel title="Select Date" />
              <Input
                  type="datetime-local"
                  className="shadow-none date-input"
                  placeholder="Select Date"
                  id="schedule-time"
                  onChange={inputdata}
                  value={formData?.schedule || moment().format("YYYY-MM-DDTHH:mm")}
                  name="schedule"
                  min={moment().format("YYYY-MM-DDTHH:mm")}
                  max={moment(expiry_date).format("YYYY-MM-DDTHH:mm")}
                />
              <ValidationFeedback title={formError?.schedule} />
            </div>
          </div>

          <div className="mb-4">
            <h5 className="fw-medium text-capitalize">Customize PDF Name</h5>
          </div>
          <div className="grid">
            <div className="g-col-4">
              <div>
                <CommonFormLabel title="Pdf Name" />
                <Input
                  type="text"
                  className="shadow-none date-input"
                  placeholder="Customized PDF Name"
                  id="schedule-time"
                  onChange={handlePdfNameChange}
                  value={pdfName}
                  
                />
                <div className="d-flex align-items-center gap-2 mt-2 mb-4">

                  <p>
                    Your Default Pdf name will be  
                    <span className="fw-semibold">Invitation.pdf</span>
                  </p>
                </div>


                {csvKeys &&
                Array.from(csvKeys).map((rows, i) => {
                  return (
                    rows === "name" && (
                      <CommomButton
                        key={i}
                        title={`[[${rows}]]`}
                        onClick={(e) => {
                          e.preventDefault();
                          addVariableInPdfName(rows);
                        }}
                        className="btn-outline-primary bg-transparent"
                      />
                    )
                  );
                })}
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="bg-white rounded-2 common-shadow p-4 mb-4">
        <div className="add-message-content">
          {/* <div className="mb-4">
            <h5 className="fw-medium text-capitalize">Delay Second</h5>
          </div>
          <div className="grid gap-4 mb-4">
            <div className="g-col-2">
              <CommonFormLabel title="From" />
              <Input
                type="text"
                className="shadow-none "
                min={0}
                max={10}
                maxLength={2}
                onChange={inputdata}
                value={formData?.delayFromSecond}
                name="delayFromSecond"
              />
              <ValidationFeedback title={formError?.delayFromSecond} />
            </div>
            <div className="g-col-2">
              <CommonFormLabel title="To" />
              <Input
                type="text"
                className="shadow-none"
                min={0}
                max={10}
                maxLength={2}
                onChange={inputdata}
                value={formData?.delayToSecond}
                name="delayToSecond"
              />
              <ValidationFeedback title={formError?.delayToSecond} />
            </div>
          </div> */}
          <div className="mb-4">
            <h5 className="fw-medium text-capitalize">Schedule Message</h5>
          </div>
          <div className=" rounded-2 d-flex align-items-center justify-content-start gap-2 mt-3 mb-3 allow-duplicates-contnt">
            <Checkbox
              id="allow-country-code"
              name="allow-country-code"
              checked={isAllowSchedule}
              onChange={(e) => {
                console.log("Checked status : ", e.target.checked);
                if (!e.target.checked) {
                  console.log("False Case ::: ");
                  // handleChipClick("")
                  setFormData({
                    ...formData,
                    schedule: "",
                  });
                }
                setIsAllowSchedule(e.target.checked);
                console.log("Formdata for country code :", formData);
              }}
              className="md-checkbox"
            />
            <p>Schedule Campaign</p>
          </div>
          {isAllowSchedule ? (
            <div className="grid mb-4">
              <div className="g-col-4">
                <CommonFormLabel title="Select Date" />
                <Input
                  type="datetime-local"
                  className="shadow-none date-input"
                  placeholder="Select Date"
                  id="schedule-time"
                  onChange={inputdata}
                  value={
                    formData?.schedule || moment().format("YYYY-MM-DDTHH:mm")
                  }
                  name="schedule"
                  min={moment().format("YYYY-MM-DDTHH:mm")}
                  max={moment(expiry_date).format("YYYY-MM-DDTHH:mm")}
                />
                <ValidationFeedback title={formError?.schedule} />
              </div>
            </div>
          ) : null}

          {/* <div className="mb-4">
            <h5 className="fw-medium text-capitalize">Customize PDF Name</h5>
          </div>
          <div className="grid">
            <div className="g-col-4">
              <div>
                <CommonFormLabel title="Pdf Name" />
                <Input
                  type="text"
                  className="shadow-none date-input"
                  placeholder="Customized PDF Name"
                  id="schedule-time"
                  onChange={handlePdfNameChange}
                  value={pdfName}
                  
                />
                <div className="d-flex align-items-center gap-2 mt-2 mb-4">

                  <p>
                    Your Default Pdf name will be  
                    <span className="fw-semibold">Invitation.pdf</span>
                  </p>
                </div>


                {csvKeys &&
                Array.from(csvKeys).map((rows, i) => {
                  return (
                    rows === "name" && (
                      <CommomButton
                        key={i}
                        title={`[[${rows}]]`}
                        onClick={(e) => {
                          e.preventDefault();
                          addVariableInPdfName(rows);
                        }}
                        className="btn-outline-primary bg-transparent"
                      />
                    )
                  );
                })}
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <Row>
        <Col lg="6" sm="3" xs="4" className="border_rightcol p-2">
          <Row>
            <Col xl="12" className="mb-25 ">
              <div className="mb-75">
                <Row className="d-flex align-items-center py-5">
                  <Col lg="9" sm="9" xs="8">
                    <div className=" d-flex my-75  text-light justify-content-between align-items-center">
                      <h4
                        className="ps-1 m-0"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        Message
                      </h4>
                    </div>
                  </Col>
                  <Col lg="3" sm="3" xs="4">
                    <div className="pe-1 float-end">
                      <Button
                        color="primary"
                        onClick={() => {
                          setMessageData("");
                        }}
                        className="mx-25"
                        type="reset"
                      >
                        Clear
                      </Button>
                    </div>
                  </Col>
                </Row>

                <div className="pt-2">
                  <div className="position-relative floating-label-content">
                    <label className="floating-label text-start w-100">
                      Enter Message
                    </label>
                    <textarea
                      id="message"
                      ref={inputRef}
                      className="manual-textarea w-100 bg-white fs-16 floating-textarea"
                      placeholder=" "
                      autoFocus
                      row={5}
                      name="textMessage"
                      onBlur={onBlur}
                      value={messageData}
                      onChange={handleChangeForMessage}
                      onSelect={currentSelection}
                      style={{
                        height: "150px",
                        borderRadius: "5px",
                        "input[type=text]:focus, textarea:focus": {
                          outline: "none !important",
                          border: "1px solid red",
                          boxShadow: "0 0 10px #719ECE",
                        },
                      }}
                    />
                  </div>
                </div>

                <Row className="d-flex align-items-center py-3">
                  <Col lg="8" className="text-start">
                    {csvKeys &&
                      Array.from(csvKeys).map((rows, i) => {
                        return (
                          rows !== "number" && (
                            <Label
                              key={i}
                              onClick={(e) => {
                                e.preventDefault();
                                addVariableInMessage(rows);
                              }}
                              className="btn_media btn btn-sm p-50 m-25 m-0"
                              style={{ fontWeight: "500", fontSize: "10px" }}
                            >
                              [[{rows}]]
                            </Label>
                          )
                        );
                      })}
                  </Col>
                  <Col lg="4">
                    <div className="d-flex justify-content-center align-items-center  justify-content-lg-end justify-content-md-center justify-content-sm-center p-50">
                      <span className="me-2 curser-pointer">
                        <EmojiPickerWrapper onEmojiClick={onEmojiClick} />
                      </span>
                      <span className="curser-pointer">
                        <Bold
                          onClick={() => {
                            textFormatting("b");
                          }}
                          className="cursor-pointer me-2"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </span>
                      <span className="curser-pointer">
                        <Italic
                          onClick={() => {
                            textFormatting("i");
                          }}
                          className="cursor-pointer me-2"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </span>
                      <span className="curser-pointer">
                        <s className="">
                          <h3
                            onClick={() => {
                              textFormatting("s");
                            }}
                            className="cursor-pointer m-0"
                            style={{ fontWeight: "400" }}
                          >
                            S
                          </h3>
                        </s>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg="4" md="3" sm="4" xs="12" className="text-start p-3">
          <div className="mb-4">
            <Label className="fs-6 mb-0 text-black">Delay Seconds</Label>
            <Row className="align-items-center">
              <Col lg="6" md="6" sm="6" xs="12">
                <div className="d-flex align-items-center my-1">
                  <Label className="fs-6 mb-0 text-black me-1">From</Label>
                  <Input
                    type="number"
                    className="w-100 fw-500 border-0 delaysec_input"
                    min={0}
                    max={10}
                    maxLength={2}
                    onChange={inputdata}
                    value={formData?.delayFromSecond}
                    name="delayFromSecond"
                  />
                </div>
                <p className="text-danger">{formError?.delayFromSecond}</p>
              </Col>
              <Col lg="6" md="6" sm="6" xs="12">
                <div className="d-flex align-items-center my-1">
                  <Label className="fs-6 mb-0 text-black me-1">To</Label>
                  <Input
                    type="number"
                    className="w-100 fw-500 border-0 delaysec_input"
                    min={0}
                    max={10}
                    maxLength={2}
                    onChange={inputdata}
                    value={formData?.delayToSecond}
                    name="delayToSecond"
                  />
                </div>
                <p className="text-danger">{formError?.delayToSecond}</p>
              </Col>
            </Row>
          </div>

          <div className="mb-4">
            <Button
              className="d-flex align-items-center justify-content-center w-100"
              color="primary"
              onClick={toggleSchedule}
              onChange={setFormData}
            >
              <Calendar className="me-1" />
              Select Schedule Date
            </Button>
          </div>

          <div className="text-start">
            <p className="my-50 text-primary fs-5">
              {formData?.schedule &&
                moment(formData?.schedule).format("DD-MM-YYYY hh:mm A")}
            </p>
          </div>
        </Col>
      </Row> */}

      <Modal
        isOpen={modalSchedule}
        toggle={toggleSchedule}
        className="modal-dialog-centered"
        centered
      >
        <ModalHeader toggle={toggleSchedule} className="modal_header border-0">
          Schedule Send
        </ModalHeader>
        <ModalBody className="modal_body py-3">
          <div className="position-relative floating-label-content">
            <Input
              type="datetime-local"
              id="schedule-time"
              onChange={inputdata}
              value={formData?.schedule}
              name="schedule"
              min={moment().format("YYYY-MM-DD HH:mm")}
              max={moment(expiry_date).format("YYYY-MM-DDTHH:mm")}
              className="w-100 bg-white fs-16 floating-textarea manual_input"
            />
            <Label className="bg-white position-absolute floating-label">
              Select Date
            </Label>
            <p className="text-danger">{formError?.schedule}</p>
          </div>
        </ModalBody>
        <ModalFooter className="border-0 d-inline pt-5 modal_footer">
          <div className="text-center">
            <Button
              color="secondary"
              onClick={() => {
                setFormData({
                  ...formData,
                  schedule: "",
                });
                toggleSchedule();
              }}
              className="mx-25 me-4"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                toggleSchedule();
              }}
              className="mx-25"
              disabled={formError?.schedule ? true : false}
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      </Modal>

      {/* Test Modal  */}
      {/* {console.log("ncjadsbckjasdjcksadkcaks : ", isTestOpen)} */}
      <Modal show={isTestOpen} size="sm" centered className="">
        <ModalHeader toggle={toggleTestModal} className="modal_header ">
          <h5 className="mb-0">Test Message</h5>
        </ModalHeader>
        <ModalBody>
          <Label>Enter Mobile Number</Label>
          <Input
            name="mobile"
            value={testMobile}
            onChange={(e) => {
              setTestMobile(e.target.value);
            }}
            type="number"
          />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between align-items-baseline">
          <div className="d-flex gap-2">
            <Button
              color="primary"
              className="mx-25"
              onClick={(e) => {
                sendTestMessage();
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Send"}
            </Button>
            <Button
              className="mx-25"
              color="secondary"
              onClick={() => {
                toggleTestModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InsertMessage;
